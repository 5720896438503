import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
    margin: 0 auto;
  max-width: 650px;
  
  @media (min-width: 728px){
  margin-top: 32px;
     margin-bottom: 64px;

  }
  @media (max-width: 727.98px) {
     margin-top: 24px;
     margin-right: 32px;
     margin-left: 32px;
     margin-bottom: 64px;
  }
`
const RecommendationHeader = styled.h2`
    position: relative;
    font-size: 1.25em;
    font-weight: 600; 
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
`

const Box = styled.div`
  margin-top: 12px;
  box-shadow: inset 0 0 0 1px rgb(230 230 230);
`;

const RecommendationLink = styled.a``;

const Text = styled.div`
      padding: 0px;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
`;

const TextContainer = styled.div`
  flex: 1 1 auto;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  display: flex;
`;


const Heading = styled.h2`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
  color: rgba(41, 41, 41, 1);
  font-weight: 700;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 20px;
`;

const Faint = styled.div`
  margin-top: 12px;
  display: block;
  font-size: 13px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
  color: rgba(117, 117, 117, 1);
  line-height: 20px;
  font-weight: 400;
`;

const ImgBox = styled.div`
  width: 160px;
  display: block;
      z-index: -1;

`;

const StyledImg = styled.img`
  background-position: 50% 50%;
  background-size: cover;
  background-origin: border-box;
  width: 160px;
  box-shadow: inset 0 0 0 1px rgb(230 230 230);
  max-width: 100%;
  display: block;

`;

const Post = props => {

  return (
    <Box>
      <RecommendationLink href={"../../../" + props.post.slug}>
        <Text>
          <TextContainer>
            <Heading>{props.post.title}</Heading>
            <Faint>{props.post.publishDate}</Faint>
          </TextContainer>
          <ImgBox>
            <StyledImg src={props.post.heroImage.fluid.src} alt={props.post.title} loading="lazy"/>
          </ImgBox>
        </Text>
      </RecommendationLink>
    </Box>
  )
}

const Recommendation = props => {

  return (
    <Wrapper>
      <RecommendationHeader>You May Also Like</RecommendationHeader>
      <Post post={props.posts[0]}/>
      <Post post={props.posts[1]}/>
    </Wrapper>
  )
}

export default Recommendation
